import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {  Stack, IconButton, InputAdornment, TextField, } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../../context/auth';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { signed, Login } = useAuth();

  console.log(signed);

  async function handleLogin() {
    await Login({
      email: 'rafaelcodomingues@gmail.com',
      senha: '123456',
    });
  }
 

  const [showPassword, setShowPassword] = useState(false);



  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email" />

        <TextField
          name="senha"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => {handleLogin()}} style={{marginTop: '25px'}}>
        Entrar
      </LoadingButton>
    </>
  );
}
