import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes

// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import {AuthProvider} from './context/auth';
import Routes from './routes';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <AuthProvider>
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Routes />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
    </AuthProvider>
  );
}
