import PropTypes from 'prop-types';
import Axios from 'axios';
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Button } from '@mui/material';
import Iconify from '../../../components/iconify';



const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

StoresListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onHandleRefresh: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function StoresListToolbar({ numSelected, filterName, onFilterName, selectedIds,onHandleRefresh }) {
  const handleActivate = () => {
    selectedIds.forEach((id) => {
      Axios.post(
        "https://xmanager.xsistemas.com.br/api/store/status",
        {
          cnpj: id,
          status: true,
        }
      ).then((response) => {
        console.log(response);
        onHandleRefresh();
      }).catch((error) => {
        console.error(error);
        onHandleRefresh();
      });
    });
    
  };
  
  const handleDeactivate = () => {
    selectedIds.forEach((id) => {
      Axios.post(
        "https://xmanager.xsistemas.com.br/api/store/status",
        {
          cnpj: id,
          status: false,
        }
      ).then((response) => {
        console.log(response);
        onHandleRefresh();
      }).catch((error) => {
        console.error(error);
        onHandleRefresh();
      });
    });
  
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selecionado(s)
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Buscar..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <div style={{}}>
          <Tooltip title="Ativar Loja">
            <Button
              variant="contained"
              color="success"
              startIcon={<Iconify icon="mdi:storefront-check" />}
              style={{ marginRight: '10px' }}
              onClick={handleActivate}
            >
              Ativar
            </Button>
            
          </Tooltip>
          <Tooltip title="Desativar">
            <Button
              variant="contained"
              color="error"
              startIcon={<Iconify icon="mdi:storefront-remove" />}
              onClick={handleDeactivate}
            >
              Desativar
            </Button>
          </Tooltip>
        </div>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
}
